.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.time-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.to-label {
  margin-left: 10px;
}

.time-container label {
  margin-right: 5px;
}

.form-container {
  display: flex;
  justify-content: center;
  align-self: center;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 13em;
  padding: 0;
  margin: 0;
}

.day-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-trash {
  margin-left: 1em;
  color: gray;
  cursor: pointer;
}

.day-heading-container .day-trash:hover {
  color: red;
}

.nav-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4em;
  left: 0;
  top: 0;
}

.nav-container .nav-logo {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  margin-left: 1em;
}

.nav-container .nav-logo h1 {
  text-align: left;
}

.nav-container a {
  font-size: 2em;
  text-decoration: none;
  color: black;
}

.nav-container a:hover {
  border-bottom: 2px solid black;
}

.nav-container a:last-child {
  margin-right: 1em;
}

.nav-container ul li {
  list-style-type: none;
  display: inline-block;
}

.container {
  margin-top: 5em;
}

.active {
  border-bottom: 2px solid black;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20em;
}

.profile-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.button-container {
  margin-top: 20px;
  align-self: flex-end;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10em;
}

.button-container button {
  margin: 5px;
}

.profile-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

#date-heading {
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

#date-container {
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.date-heading-text {
  margin-right: 5px;
}

.error {
  color: red;
}

iframe {
  width: 80% !important;
  height: 100% !important;
}

#pdf-container {
  height: 90vh;
}
